
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { ContactUsObject } from '@/store/modules/settings/ContactUsModule';
  import { useAbility } from '@casl/vue';
  import Lightgallery from 'lightgallery/vue';
  import lgZoom from 'lightgallery/plugins/zoom';

  export default defineComponent({
    name: 'Contact-us-overview',
    components: {
      Lightgallery,
    },
    data: () => ({
      plugins: [lgZoom],
    }),
    methods: {
      onInit: () => {
        console.log('lightGallery has been initialized');
      },
      onBeforeSlide: () => {
        console.log('calling before slide');
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('contactUsOverview'), [
          translate('settings'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_CONTACT,
        route.params.id
      );

      const contactUs = ref<ContactUsObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      return {
        translate,
        can,
        contactUs,
      };
    },
  });
